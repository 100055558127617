import React from "react";
import { graphql } from "gatsby";
import Hero from "../components/hero";
import MobileNav from "../components/mobile-nav"
import Footer from "../components/footer"
import SiteMetaData from "../components/sitemeta";
import teamStyles from "../components/teams.module.scss";
import "../styles/layout.scss";

const Layout = ({ children }) => (
  <div>
    {children}
    <Footer />
    <MobileNav />
  </div>
)

const TeamData = props => {
  const {
    name,
    ctaHeader,
    makeup,
    subheading,
    children,
    date,
    date2,
    comment,
    link,
    link2
  } = props;
  return (
  <div className={teamStyles.team}>
    <div className="columns">
      <div className={`${teamStyles.details} display--inline-block column is-three-quarters`}>
        <h4>{name} <span>{subheading}</span></h4>
        {children}
      </div>
      <div className={`${teamStyles.cta} display--inline-block column is-one-quarter`}>
        {ctaHeader ? <p>{ctaHeader}</p> : ''}
        <p>
          <span style={({'display':`block`})}>{date}</span>

          {makeup && (
            <span style={({'display':`block`,'fontSize':`0.9em`})}>
              <em>
                <a target="_blank" rel="noopener noreferrer" href="https://www.eventbrite.com/e/rf-make-up-audition-registration-59088611628" title="Make up audition sign up">Make up auditions</a> are March 31.
              </em>
            </span>
          )}

          {comment && (
            <span style={{'display':`block`,'fontSize':`0.9em`}}>
              <em>{comment}</em>
            </span>
          )}
        </p>
        {link && (
          <a className="button is-info is-rounded" href={link}>Sign up</a>
        )}
        {link2 && 
          <div style={({'marginTop':`2em`})}>
            <p>{date2}</p>
            <a className="button is-info is-rounded" href={link2}>Sign up</a>
          </div>}
      </div>
    </div>
  </div>
)}

const TeamPage = ({data}) => {
  const { 
    name, 
    display, 
    displayRed, 
    displayWhite, 
    desp,
    metaTitle,
    metaDescription,
    auditions
  } = data.teamsYaml;
  return (
  <Layout>
    <div style={({"backgroundColor":"black"})}>
      <SiteMetaData 
        title={metaTitle}
        description={metaDescription}
        pathname={`teams/${name}`}/>
      <Hero 
        titleRed={displayRed || `RF`}
        titleWhite={displayWhite || `Teams`} />

      <div className={`${teamStyles.auditions} content-block`}>
        <div className={`${teamStyles.container} container`}>
          {auditions.map((audition, index) => {
            const { classification, level, description, date, link , date2, link2 } = audition;
            return (
            <TeamData
              name={classification || display}
              subheading={level || `All levels`}
              link={link || null}
              date={date || null}
              date2={date2 || null}
              link2={link2 || null}
              key={`${name}-${index}`}
              >
              {description && <p>{description}</p>}
              </TeamData>
            )
          })}
        </div>
      </div>
    </div>
  </Layout>
)};

export default TeamPage

export const query = graphql`
  query($name: String!) {
    teamsYaml(name: { eq: $name }) {
      name
      display
      displayRed
      displayWhite
      desp
      metaTitle
      metaDescription
      auditions {
        classification
        level
        description
        date
        link
        link2
        date2
      }
    }
  }
`;